export default {
  'zh': {
    'ERR_MSG_20000': '请求成功',
    'ERR_MSG_50000': '请求错误',
    'ERR_MSG_50001': '请求参数为空',
    'ERR_MSG_50002': '无数据',
    'ERR_MSG_50003': '该链接已失效',
    'ERR_MSG_50004': '二维码已经超过了限额',
    'ERR_MSG_50005': '非法连接或签名失败',
    'ERR_MSG_50006': '该链接无效',
    'ERR_MSG_50007': '报名未开始，敬请期待',
    'ERR_MSG_50008': '报名已结束',
    'ERR_MSG_50009': '该链接已经占用',

    //初始化
    'ERR_MSG_50010': '数据验证错误',
    'ERR_MSG_50011': '页面初始化出错',
    'ERR_MSG_50012': '提交出错',
    'ERR_MSG_50013': '预先登记免费通道已结束',

    //邮箱
    'ERR_MSG_50020': '请输入邮箱地址',
    'ERR_MSG_50021': '发送邮箱验证码出错',
    'ERR_MSG_50022': '请输入邮箱地址和验证码',
    'ERR_MSG_50023': '邮箱验证码验证出错',
    'ERR_MSG_50024': '该邮箱已经被注册',

    //短信
    'ERR_MSG_50030': '请输入手机号码',
    'ERR_MSG_50031': '短信发送错误',
    'ERR_MSG_50032': '请输入手机号码和验证码',
    'ERR_MSG_50033': '验证码校验错误',
    'ERR_MSG_50034': '获取倒计时出错',
    'ERR_MSG_50035': '该手机号码已经被注册',
    'ERR_MSG_50036': '未找到该员工，请先导入内部工作人员通讯录',
    'ERR_MSG_50039': '昵称不能包含特殊字符或者表情包',

    //人脸
    'ERR_MSG_50040': '未识别到人脸',
    'ERR_MSG_50041': '人脸识别出错',
    'ERR_MSG_50042': '系统异常',
    'ERR_MSG_50043': '"识别失败',
    'ERR_MSG_50044': '请靠近中部',
    'ERR_MSG_50045': '信息不存在',
    'ERR_MSG_50046': '人脸添加失败',
    'ERR_MSG_50047': '人脸遮挡严重',
    'ERR_MSG_50048': '照片光照不良',
    'ERR_MSG_50049': '照片清晰度不够',
    'ERR_MSG_50050': '照片质量低',
    'ERR_MSG_50051': '不能闭上眼睛',
    'ERR_MSG_50052': '人脸未摆正',
    'ERR_MSG_50053': '人脸未正对',
    'ERR_MSG_50054': '人脸未居中',
    'ERR_MSG_50055': '图片中包含多个人脸',
    'ERR_MSG_50056': '不能戴口罩',
    'ERR_MSG_50057': '不能戴帽子',
    'ERR_MSG_50058': '不能戴眼镜',
    'ERR_MSG_50059': '不能遮挡眉毛',
    'ERR_MSG_50060': '不能遮挡眼睛',
    'ERR_MSG_50061': '不能遮挡鼻子',
    'ERR_MSG_50062': '不能遮挡脸颊',
    'ERR_MSG_50063': '不能遮挡嘴巴',
    'ERR_MSG_50064': '不能遮挡下巴',
    'ERR_MSG_50065': '上传图片过大',
    'ERR_MSG_50066': '五官遮挡严重',
    'ERR_MSG_50067': '活体检测不通过',
    'ERR_MSG_50068': '创建用户出现异常',
    'ERR_MSG_50069': '获取人脸信息出现异常',
    'ERR_MSG_50070': '无法识别到人脸',

    //验证码
    'ERR_MSG_50077': '验证码错误',

    //名片
    'ERR_MSG_50078': '系统无法识别您的名片信息',
    'ERR_MSG_50079': '姓名无法识别',
    'ERR_MSG_500304':'该名片已存在',

    //会籍卡
    'ERR_MSG_50080': '读取会籍卡信息错误',
    'ERR_MSG_50081': '未读取到报名信息',

    //问卷

    //文件上传
    'ERR_MSG_50100': '上传出错',
    'ERR_MSG_50101': '上传文件超过10MB大小限制！',

    //其他业务
    'ERR_MSG_50110': '获取区域信息出错',
    'ERR_MSG_50111': '获取客户类型出错',
    'ERR_MSG_50112': '验证是否报名完成出错',
    'ERR_MSG_50113': '提交报名完成出错',
    'ERR_MSG_50114': '关注微信公众号出错',
    'ERR_MSG_50115': '获取位置出错',
    'ERR_MSG_50116': '获取部门任务指标出错',
    'ERR_MSG_50117': '获取报名流程出错',

    //海报
    'ERR_MSG_50120': '获取海报信息出错',
    'ERR_MSG_50121': '海报保存出错',
    'ERR_MSG_50122': '更换背景出错',
    'ERR_MSG_50123': '删除海报出错',
    'ERR_MSG_50124': '获取营销推广类型出错',
    'ERR_MSG_50125': '保存营销推广类型出错',
    'ERR_MSG_50126': '删除营销推广类型出错',
    'ERR_MSG_50127': '获取任务信息出错',
    'ERR_MSG_50128': '获取邀请码详情出错',
    'ERR_MSG_50129': '保存拆分任务出错',
    'ERR_MSG_50130': '更新任务指标出错',
    'ERR_MSG_50131': '该海报已删除',
    'ERR_MSG_50132': '请填写海报有效时间',
    'ERR_MSG_50133': '客户名称重复',
    'ERR_MSG_50134': '此海报已经报名，不可删除',
    'ERR_MSG_50135': '手机号重复',
    "ERR_MSG_50137": '请勿频繁请求，请稍后重试。',
    
    //客户类型:customerType
    'ERR_MSG_50200': '法老VIP',
    'ERR_MSG_50201': '国王VIP',
    'ERR_MSG_50202': '王爵VIP',
    'ERR_MSG_50203': '公爵VIP',
    'ERR_MSG_50204': '精装住宅展VIP',
    'ERR_MSG_50205': '灯饰艺术展VIP',
    'ERR_MSG_50206': '工作环境展VIP',
    'ERR_MSG_50207': '工作人员',
    'ERR_MSG_50208': '内部员工',
    'ERR_MSG_50209': '海外国王VIP',
    'ERR_MSG_50210': '海外普通观众',
    'ERR_MSG_50211': '现场登记客人',
    'ERR_MSG_50212': '普通客人',
    
    //流程类型:flowName
    'ERR_MSG_50230': '预先登记团体',
    'ERR_MSG_50231': '法老VIP不拍名片',
    'ERR_MSG_50232': '国王VIP(中文)拍名片',
    'ERR_MSG_50233': '国王VIP(中文)不拍名片',
    'ERR_MSG_50234': '王爵VIP拍名片',
    'ERR_MSG_50235': '王爵VIP不拍名片',
    'ERR_MSG_50236': '公爵VIP拍名片',
    'ERR_MSG_50237': '公爵VIP不拍名片',
    'ERR_MSG_50238': '精装住宅展VIP拍名片',
    'ERR_MSG_50239': '精装住宅展VIP不拍名片',
    'ERR_MSG_50240': '灯饰艺术展VIP',
    'ERR_MSG_50241': '工作环境展VIP',
    'ERR_MSG_50242': '主办单位合作方',
    'ERR_MSG_50243': '媒体',
    'ERR_MSG_50244': '主办方特邀摄影师',
    'ERR_MSG_50245': '金汐奖评委',
    'ERR_MSG_50246': '主办方展盾律师团',
    'ERR_MSG_50247': '知识产权保护小组',
    'ERR_MSG_50248': '内部工作人员',
    'ERR_MSG_50249': '参展商工作人员',
    'ERR_MSG_50250': '普通用户预登记',
    'ERR_MSG_50251': '普通用户现场登记',
    'ERR_MSG_50252': '', //展商重要客人
    'ERR_MSG_50253': '金堂奖',
    'ERR_MSG_50254': '参展商邀约客户',
    //团体登记
    "ERR_MSG_50037": '该用户未进行团体登记',
    "ERR_MSG_50038": '该手机号未导入后台',
    "ERR_MSG_500067":'该用户已是团体请回原始链接报名',
    "ERR_MSG_500360" :'请按团体类型报名',
    "ERR_MSG_500361" :'请按主办单位合作方报名',
    "ERR_MSG_500362" :'请按C端现场客户类型报名',
    //身份证验证
    '-1': '姓名和身份证号不一致',
    '-2':' 非法身份证号（长度、校验位等不正确）',
    '-3': '非法姓名（长度、格式等不正确）',
    '-4': '证件库服务异常',
    '-5': '证件库中无此身份证记录',
    '-6': '一个身份证只能注册一个用户，不能重复注册',
    //实名认证短信验证
    "ERR_MSG_500355": '该手机号码未登记，请前往“深圳家具展”官方公众号菜单栏点击“预先登记”开始登记',
    "ERR_MSG_500356": '该手机号码未报名成功，请前往“深圳家具展”官方公众号菜单栏点击“预先登记”继续登记',
    "ERR_MSG_500357": '该手机号码已经完成实名认证，请前往“深圳家具展”官方公众号菜单栏点击“预先登记”获取专属入场资格确认卡',
    //协助登记短信验证
    "ERR_MSG_500358": '该手机号码已经报名成功',
    "ERR_MSG_500359": '当前客人需扫普通现场观众二维码登记',
    "ERR_MSG_50071": '您当前不是C端用户，请联系客服',
  },
  'en': {
    'ERR_MSG_20000': 'Request successful',
    'ERR_MSG_50000': 'Request error',
    'ERR_MSG_50001': 'Request parameter is empty',
    'ERR_MSG_50002': 'no data',
    'ERR_MSG_50003': 'The link has expired',
    'ERR_MSG_50004': 'The QR code has exceeded the limit',
    'ERR_MSG_50005': 'Illegal connection or signature failure',
    'ERR_MSG_50006': 'The link is invalid',
    'ERR_MSG_50007': 'Registration has not started, so stay tuned',
    'ERR_MSG_50008': 'Registration closed',
    'ERR_MSG_50009': 'The link is already occupied',

    //初始化
    'ERR_MSG_50010': 'Data validation error',
    'ERR_MSG_50011': 'Page initialization error',
    'ERR_MSG_50012': 'Submission error',
    'ERR_MSG_50013': 'Pre-registration for free channel has ended',

    //邮箱
    'ERR_MSG_50020': 'Please input the email address',
    'ERR_MSG_50021': 'Error sending email verification code',
    'ERR_MSG_50022': 'Please enter your email address and verification code',
    'ERR_MSG_50023': 'Email verification code verification error',
    'ERR_MSG_50024': 'The email has been registered',

    //短信
    'ERR_MSG_50030': 'Please enter the phone number',
    'ERR_MSG_50031': 'SMS sending error',
    'ERR_MSG_50032': 'Please enter your phone number and verification code',
    'ERR_MSG_50033': 'verification code verification error',
    'ERR_MSG_50034': 'Error retrieving countdown',
    'ERR_MSG_50035': 'The mobile number is already registered',
    'ERR_MSG_50036': 'The employee was not found, please import the internal staff address book first',

    //人脸
    'ERR_MSG_50040': 'No faces recognized',
    'ERR_MSG_50041': 'recognition unsuccessful',
    'ERR_MSG_50042': 'System exception',
    'ERR_MSG_50043': 'Recognition unsuccessful',
    'ERR_MSG_50044': 'Please come to the middle',
    'ERR_MSG_50045': 'Information does not exist',
    'ERR_MSG_50046': 'Face addition failed',
    'ERR_MSG_50047': 'Face occlusion is severe',
    'ERR_MSG_50048': 'Photos are poorly lit',
    'ERR_MSG_50049': 'The photos are not sharp enough',
    'ERR_MSG_50050': 'Photo quality is low',
    'ERR_MSG_50051': 'do not close your eyes',
    'ERR_MSG_50052': 'keep your face straight',
    'ERR_MSG_50053': 'keep your face straight',
    'ERR_MSG_50054': 'Face is not centered',
    'ERR_MSG_50055': 'Picture contains multiple faces',
    'ERR_MSG_50056': 'No mask',
    'ERR_MSG_50057': 'No hats',
    'ERR_MSG_50058': 'no glasses',
    'ERR_MSG_50059': 'do not cover eyebrows',
    'ERR_MSG_50060': 'do not cover the eyes',
    'ERR_MSG_50061': 'do not cover the nose',
    'ERR_MSG_50062': 'do not cover cheeks',
    'ERR_MSG_50063': 'do not cover mouth',
    'ERR_MSG_50064': 'do not cover chin',
    'ERR_MSG_50065': 'photo size is too large',
    'ERR_MSG_50066': 'The facial features are heavily obscured',
    'ERR_MSG_50067': 'Failed biopsy',
    'ERR_MSG_50068': 'Create user exception',
    'ERR_MSG_50069': 'Abnormality in obtaining face information',
    'ERR_MSG_50070': 'Unable to recognize face',

    //验证码
    'ERR_MSG_50077': 'Verification code error',

    //名片
    'ERR_MSG_50078': 'The System is Unable to Recognize Business Card Information',
    'ERR_MSG_50079': 'Name unidentifiable',
    'ERR_MSG_500304':'The card already exists',

    //会籍卡
    'ERR_MSG_50080': 'Error reading Identification card information',
    'ERR_MSG_50081': 'Registration information not read',

    //问卷

    //文件上传
    'ERR_MSG_50100': 'Upload error',
    'ERR_MSG_50101': 'Uploaded file exceeds 10MB size limit',

    //其他业务
    'ERR_MSG_50110': 'Error getting area information',
    'ERR_MSG_50111': 'Error getting customer type',
    'ERR_MSG_50112': 'Verify that there was an error in completing the registration',
    'ERR_MSG_50113': 'Error submitting registration',
    'ERR_MSG_50114': 'Follow WeChat public account error',
    'ERR_MSG_50115': 'Error getting location',
    'ERR_MSG_50116': 'Error getting department task indicators',
    'ERR_MSG_50117': 'Error getting registration process',

    //海报
    'ERR_MSG_50120': 'en 获取海报信息出错',
    'ERR_MSG_50121': 'en 海报保存出错',
    'ERR_MSG_50122': 'en 更换背景出错',
    'ERR_MSG_50123': 'en 删除海报出错',
    'ERR_MSG_50124': 'en 获取营销推广类型出错',
    'ERR_MSG_50125': 'en 保存营销推广类型出错',
    'ERR_MSG_50126': 'en 删除营销推广类型出错',
    'ERR_MSG_50127': 'en 获取任务信息出错',
    'ERR_MSG_50128': 'en 获取邀请码详情出错',
    'ERR_MSG_50129': 'en 保存拆分任务出错',
    'ERR_MSG_50130': 'en 更新任务指标出错',
    'ERR_MSG_50131': 'en 该海报已删除',
    'ERR_MSG_50132': 'en 请填写海报有效时间',
    'ERR_MSG_50133': 'en 客户名称重复',
    'ERR_MSG_50134': 'en 此海报已经报名，不可删除',
    
    //客户类型:customerType
    'ERR_MSG_50200': 'en 法老VIP',
    'ERR_MSG_50201': 'en 国王VIP',
    'ERR_MSG_50202': 'en 王爵VIP',
    'ERR_MSG_50203': 'en 公爵VIP',
    'ERR_MSG_50204': 'en 精装住宅展VIP',
    'ERR_MSG_50205': 'en 灯饰艺术展VIP',
    'ERR_MSG_50206': 'en 工作展VIP',
    'ERR_MSG_50207': 'en 工作人员',
    'ERR_MSG_50208': 'en 内部员工',
    'ERR_MSG_50209': 'IMPERATOR VIP',
    'ERR_MSG_50210': 'en 海外普通观众',
    'ERR_MSG_50211': 'en 现场登记客人',
    'ERR_MSG_50212': 'en 普通客人',
    
    //流程类型:flowName
    'ERR_MSG_50230': 'en 预先登记团体',
    'ERR_MSG_50231': 'en 法老VIP不拍名片',
    'ERR_MSG_50232': 'en 国王VIP(中文)拍名片',
    'ERR_MSG_50233': 'en 国王VIP(中文)不拍名片',
    'ERR_MSG_50234': 'en 王爵VIP拍名片',
    'ERR_MSG_50235': 'en 王爵VIP不拍名片',
    'ERR_MSG_50236': 'en 公爵VIP拍名片',
    'ERR_MSG_50237': 'en 公爵VIP不拍名片',
    'ERR_MSG_50238': 'en 精装住宅展VIP拍名片',
    'ERR_MSG_50239': 'en 精装住宅展VIP不拍名片',
    'ERR_MSG_50240': 'en 灯饰艺术展VIP',
    'ERR_MSG_50241': 'en 工作展VIP',
    'ERR_MSG_50242': 'en 主办单位合作方',
    'ERR_MSG_50243': 'en 媒体',
    'ERR_MSG_50244': 'en 主办方特邀摄影师',
    'ERR_MSG_50245': 'en 金汐奖评委',
    'ERR_MSG_50246': 'en 主办方展盾律师团',
    'ERR_MSG_50247': 'en 知识产权保护小组',
    'ERR_MSG_50248': 'en 内部工作人员',
    'ERR_MSG_50249': 'en 展商工作人员',
    'ERR_MSG_50250': 'en 普通用户预登记',
    'ERR_MSG_50251': 'en 普通用户现场登记',
    'ERR_MSG_50252': '',//展商重要客人
    'ERR_MSG_50253': 'en 金堂奖',
    'ERR_MSG_50254': 'Guests invited by exhibitors',
    //身份证验证
    '-1': 'The name is inconsistent with the ID number',
    '-2': 'Illegal ID number (incorrect length, check digit, etc.)',
    '-3': 'Illegal name (incorrect length, format, etc.)',
    '-4': 'Database service is abnormal',
    '-5': 'There is no record of this identity card in the library',
    '-6': 'An ID can only register a user, can not be repeated registration'
  }
}
