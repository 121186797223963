<template>
	<div class="page-container flex justify-center align-center">
		<span v-html="errorMessage || ''"></span>
	</div>
</template>

<script>
	import errMsgConfig from '@/view/config/errMsgSet.js';
	
	export default {
		name: 'errorTips',
		data() {
			return {
				errorMessage: '无'
			}
		},
		created() {
			let {errCode, errorMessage} = this.$route.query;
			errCode && (this.errorMessage = errMsgConfig[localStorage.getItem('lang') ||'zh'][errCode] || 'No Error Code');
			
			// 分割成数组
			errorMessage ? this.errorMessage = errorMessage : '页面不存在';
		},
		methods: {

		},
	}
</script>

<style scoped>
	.page-container {
    width: 100%;
    max-width: 750Px;
		height: 100vh;
    margin: 0 auto;
		font-size: 20Px;
		line-height: 40px;
    background-color: #fff;
	}
</style>
